<template>
    <div v-if="!platonLayout">
        <slot></slot>
    </div>

    <div v-else class="platon-layout" :class="{ sticky: isSticky }">
        <PlatonLeftMenu class="platon-left-menu" />

        <div class="flex-grow-1 content platon-scrollable-layout" :class="{ collapsed: menuCollapsed }">
            <PlatonTopbar style="position: sticky; top: 0">
                <template #after-title>
                    <div class="px-2 topbar-bars-btn">
                        <b-btn class="topbar-btn" @click="collapseMenu">
                            <i class="fa fa-bars"></i>
                        </b-btn>
                    </div>
                </template>

                <template #before-lang>
                    <slot name="before-lang"></slot>
                </template>
            </PlatonTopbar>

            <div style="position: sticky; top: 52px">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"
import PlatonLeftMenu from "@Platon/components/layout/LeftMenu.vue"
import PlatonTopbar from "@Platon/components/layout/Topbar.vue"
import PluginEvents from "@Platon/core/plugins/PluginEvents"

export default {
    name: "platon-layout",
    data() {
        return {
            isSticky: false
        }
    },
    components: { PlatonTopbar, PlatonLeftMenu },

    mounted() {
        window.addEventListener("scroll", this.onScroll)
        this.$plugins.triggerEvent(PluginEvents.SetUserTheme, this.$user)
    },

    beforeDestroy() {
        window.removeEventListener("scroll", this.onScroll)
    },

    methods: {
        ...mapMutations({
            collapseMenu: "platon/toggleMenuCollapse"
        }),
        onScroll() {
            const topbarHeight = document.querySelector(".topbar")?.clientHeight || 50
            this.isSticky = window.scrollY > topbarHeight
        }
    },

    computed: {
        ...mapGetters({
            menuCollapsed: "platon/menuCollapsed"
        }),

        platonLayout() {
            return this.$route.meta.platonLayout === true
        }
    }
}
</script>
<style scoped>
.platon-left-menu {
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.platon-scrollable-layout::-webkit-scrollbar,
.platon-left-menu::-webkit-scrollbar {
    height: 0;
    width: 0;
}

.platon-scrollable-layout {
    height: 100vh;
    overflow: auto;
}
</style>
