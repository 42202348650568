<template>
    <BaseControl v-bind="$attrs" :errors="combinedErrors">
        <input
            ref="input"
            v-imask="mask"
            type="text"
            @accept="onAccept"
            @blur="emitValue"
            @input="onInput"
            :value="maskedValue"
            :placeholder="item.placeholder"
            :class="classes"
            class="form-control"
            :disabled="isDisabled"
            :readonly="isReadOnly"
            :inputmode="item.inputmode"
        />
    </BaseControl>
</template>

<script>
import BaseControl from "@Platon/components/form/controls/BaseControl.vue"
import { IMaskDirective } from "vue-imask"
import ValidationMixin from "@Platon/mixins/ValidationMixin"
import InputControl2Mixin from "@Platon/mixins/InputControl2Mixin"
import { DevLog } from "@Platon/const"

export default {
    name: "NumberControl",

    components: { BaseControl },

    mixins: [InputControl2Mixin, ValidationMixin],

    props: {
        value: {}
    },

    directives: {
        imask: IMaskDirective
    },

    data() {
        return {
            maskedValue: this.value,
            unmaskedValue: this.value,
            changed: false,
            omitUpdate: false
        }
    },

    methods: {
        async onAccept(e) {
            this.maskedValue = e.detail.value
            this.unmaskedValue = e.detail.unmaskedValue
            this.changed = true

            DevLog("NumberInput OnAccept", {
                changed: this.changed,
                omit: this.omitUpdate,
                value: this.maskedValue,
                field: this.item.dataField
            })

            this.markAsDirty()
        },

        emitValue() {
            if (this.changed && !this.omitUpdate) {
                DevLog("NumberInput EmitValue", {
                    changed: this.changed,
                    omit: this.omitUpdate,
                    value: this.maskedValue,
                    field: this.item.dataField
                })

                this.$emit("input", this.unmaskedValue)
            }

            this.omitUpdate = false
            this.changed = false
        },

        onInput() {
            this.changed = true
            this.omitUpdate = false
        }
    },

    mounted() {
        this.$watch("value", (val) => {
            this.omitUpdate = true
            this.maskedValue = val
        })
    },

    computed: {
        classes() {
            return [`text-${this.item.textAlign || "left"}`]
        },

        local() {
            return this.value
        },

        mask() {
            return {
                mask: Number,
                signed: true,
                // min: numOr(this.item.minValue, Number.MIN_SAFE_INTEGER),
                // max: numOr(this.item.maxValue, Number.MAX_SAFE_INTEGER),
                radix: ".",
                scale: this.item.scale,
                thousandsSeparator: " "
            }
        }
    }
}
</script>

<style></style>
