<template>
    <div class="QRAuthentication">
        <!-- <div class="separateBetween"> -->
        <!-- <div class="separateBetween__top"> -->
        <ul class="QRAuthentication__instructionList">
            <li class="QRAuthentication__instructionList--follow">
                Илова
                <span class="qr-application-logo" />
                <span class="mention">Google Authenticator</span>-дан + билгисини босинг
            </li>
            <li class="QRAuthentication__instructionList--follow">
                {{ "   " }}<span class="mention">ҚР кодини</span>&nbsp; - сканерлашни танланг
            </li>
        </ul>
        <div class="QRAuthentication__scanArea">
            <img
                alt="QRCode has generated by Authenticator Application"
                :src="`data:image/png;base64,${state.QRAuth}`"
                class="QRAuthentication__scanArea--image"
                v-show="state.isDone"
            />
            <div class="QRAuthentication__scanArea--backdrop" v-show="!state.isDone">
                <i class="error mdi mdi-alert-circle" v-show="state.isError" />
                <div class="loader">
                    <i class="mdi mdi-loading" v-show="!state.isError" />
                </div>
            </div>
        </div>
        <div class="QRAuthentication__subheader">
            <p class="QRAuthentication__subheader--hint">ҚР кодини сканерлай олмаяпсизми?</p>
        </div>
        <!-- </div> -->
        <!-- <div class="separateBetween__bottom">
                <div class="QRAuthentication__subPanel">
                    PANEL PANEL PANEL
                </div>
            </div> -->
        <!-- </div> -->
    </div>
</template>

<script>
export default {
    name: "QRCAuthentication",
    props: {
        state: {
            type: Object,
            default: function () {
                return {
                    QRAuth: null,
                    isDone: false,
                    isError: false
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../components/utils/mixins";

// .separateBetween {
//     height: 100%;
//     @include display-flex;
//     flex-direction: column;
//     justify-content: space-between;

//     &__top, &__bottom { width: 100%; display: block; }
// }
.QRAuthentication {
    width: $df-panel-width;
    height: 100%;

    &__instructionList {
        padding-left: 30px;

        &--follow {
            color: $df-color;
            font-size: $df-middle;
            font-weight: $df-w-lighter;
            @include display-flex;
            justify-content: flex-start;

            position: relative;
            &::after {
                content: "";
                left: -10px;
                top: 50%;
                height: 5px;
                width: 5px;
                border-radius: 50%;
                background-color: $df-color;

                transform: translateY(-50%);
                position: absolute;
            }

            & .qr-application-logo {
                display: inline-block;
                width: $df-size-small;
                height: $df-size-small;
                margin-right: 2px;
                margin-left: 5px;
                background-image: url(../../assets/2MFAIcon.png);
                background-size: cover;
            }

            & .mention {
                display: inline-block;
                font-weight: $df-w-bold;
            }
        }
    }

    &__scanArea {
        width: 100%;
        height: 220px;
        @include display-flex-center;
        background-color: $df-color-gray-200;

        &--image {
            object-fit: cover;
            height: 190px;
            width: 190px;
        }

        &--backdrop {
            & .loader {
                height: 50px;
                width: 50px;
                @include display-flex-center;
                color: $df-color-primary;

                & i {
                    font-size: 50px;
                }
                animation: s2 1s infinite linear;
                @keyframes s2 {
                    to {
                        transform: rotate(1turn);
                    }
                }
            }
        }
    }

    &__subheader {
        @include display-flex-center;
        height: 75px;

        &--hint {
            cursor: pointer;
            width: fit-content;
            display: block;
            text-align: center;
            font-weight: $df-w-middle;
            font-size: $df-middle;
            color: $df-color-primary;
            margin: 0;

            &:hover {
                color: rgba($color: $df-color-primary, $alpha: 0.8);
            }
        }
    }
}
</style>
