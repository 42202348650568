<template>
    <div class="form-control-profile-view">
        <label class="form-control-profile-view__label">
            <span v-show="required" class="required">*</span>
            {{ label }}
        </label>
        <input
            class="form-control-profile-view__input"
            :value="password"
            :placeholder="placeholder"
            :required="required"
            :type="isPasswordVisible ? 'text' : 'password'"
            v-model="password"
            @input="emitFormValueChanges"
        />
        <div class="input-detail" @click="togglePasswordVisibility">
            <i :class="isPasswordVisible ? 'mdi mdi-eye-off warn' : 'mdi mdi-eye'" />
        </div>
    </div>
</template>

<script>
export default {
    name: "formPassword",
    props: {
        label: String,
        placeholder: String,
        defaultValue: String,
        name: String,
        required: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isPasswordVisible: false,
            password: this.defaultValue
        }
    },
    methods: {
        togglePasswordVisibility() {
            this.isPasswordVisible = !this.isPasswordVisible
        },
        emitFormValueChanges() {
            this.$emit("onchange", this.name, this.password)
        }
    }
}
</script>

<style lang="scss" scoped>
@import "./utils/mixins.scss";

.form-control-profile-view {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    &__label {
        width: 100%;
        font-size: $df-size-small;
        display: inline-block;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: $df-w-middle;
        color: $df-color;
    }

    &__input {
        font-size: $df-size-small;
        height: 42px;
        border-radius: $df-border-radius;
        font-weight: $df-w-middle;
        outline: none;
        color: $df-color;
        border: 1.4px solid $df-icon-color;
        padding: 0 1rem;
        transition: border 0.2s ease;

        &:active,
        &:focus {
            border: 1.4px solid $df-color;
        }

        &::placeholder {
            color: #7f899b;
        }
    }

    &:has(.input-detail) {
        position: relative;
    }

    .input-detail {
        position: absolute;
        top: 50%;
        right: 10px;
        height: $df-size;
        width: $df-size;
        border-radius: 50%;
        @include display-flex-center;

        & i {
            height: 100%;
            width: 100%;
            cursor: pointer;
            color: $df-icon-color;
            font-size: calc($df-size - 7px);
            transition: color 0.2s ease;
            &.warn {
                color: $df-color;
            }
        }
    }

    .required {
        height: 1rem;
        width: 1rem;
        font-size: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ff7362;
    }
}
</style>
