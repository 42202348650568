<template>
    <div class="filePreviewModal">
        <Modal :footer-actions="actions" slot-class="p-0 d-flex justify-content-center">
            <template #title> Файлни кўриш </template>

            <component :is="previewComponent + '-preview'" :url="fileUrl" />
        </Modal>
    </div>
</template>

<script>
import { PlatonFileProp } from "@Platon/components/form/types"
import Modal from "@Platon/components/extended/Modal.vue"
import ImagePreview from "@Platon/components/misc/preview/ImagePreview.vue"
import PdfPreview from "@Platon/components/misc/preview/PdfPreview.vue"
import VideoPreview from "@Platon/components/misc/preview/VideoPreview.vue"
import NoPreview from "@Platon/components/misc/preview/NoPreview.vue"

const ImageExtensions = ["png", "jpg", "jpeg", "gif", "svg"]
const VideoExtensions = ["mp4", "mov", "wmv", "webm"]

export default {
    name: "FilePreviewModal",

    components: {
        Modal,
        ImagePreview,
        PdfPreview,
        VideoPreview,
        NoPreview
    },

    props: {
        remoteFile: {
            type: PlatonFileProp,
            required: true
        }
    },

    data() {
        return {}
    },

    computed: {
        previewComponent() {
            let ext = this.remoteFile.extension || ""

            if (ImageExtensions.includes(ext)) {
                return "image"
            } else if (VideoExtensions.includes(ext)) {
                return "video"
            } else if (ext === "pdf") {
                return "pdf"
            } else {
                return "no"
            }
        },

        fileUrl() {
            return this.$platonFile(this.remoteFile.id)
        },

        /**
         * @return {ModalAction[]}
         */
        actions() {
            return [
                {
                    text: "Юклаб олиш",
                    icon: "fa fa-download",
                    handler: () => {
                        this.$downloadFile(this.remoteFile.id)
                    }
                },
                { text: "Ойнани ёпиш", icon: "fa fa-times" }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.filePreviewModal {
    background: none !important;
    display: flex;
    min-width: 600px;
    align-items: center;
    justify-content: center;

    & > div {
        width: 100%;
    }
}
@media (max-width: 768px) {
    .filePreviewModal {
        min-width: 80%;
    }
}
</style>
