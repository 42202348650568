<template>
    <BaseControl
        v-bind="$attrs"
        :appendElements="(item || {}).addonElementsRight"
        :prependElements="(item || {}).addonElementsLeft"
        :errors="combinedErrors"
        ref="base"
    >
        <vue-simple-suggest
            ref="input"
            @select="onSuggestionSelect"
            :list="suggestions"
            :value.sync="maskedValue"
            v-bind="suggestionOptions"
        >
            <input
                :value.sync="maskedValue"
                @input="onInput($event.target.value)"
                :class="classes"
                :type="type"
                class="form-control"
                :disabled="disabled"
                :readonly="readonly"
                :autocomplete="$attrs.autocomplete || 'none'"
                :id="id"
                :placeholder="placeholder"
                :inputmode="$attrs.inputmode || ''"
            />

            <div
                slot="suggestion-item"
                v-if="suggestionTemplate"
                :is="suggestionTemplate"
                slot-scope="scope"
                v-bind="scope"
            ></div>
        </vue-simple-suggest>
    </BaseControl>
</template>

<script>
import BaseControl from "@Platon/components/form/controls/BaseControl.vue"
import InputControlMixin from "@Platon/mixins/InputControlMixin"
import IMask from "imask"
import ValidationMixin from "@Platon/mixins/ValidationMixin"
import VueSimpleSuggest from "vue-simple-suggest"
import debounce from "lodash/debounce"
import axios from "axios"

export default {
    name: "TextControl",
    components: { BaseControl, VueSimpleSuggest },

    mixins: [InputControlMixin, ValidationMixin],

    props: {},

    data() {
        return {
            maskedValue: null,
            mask: null,
            maskFilled: false,
            autoCompleteStyle: {
                vueSimpleSuggest: "position-relative flex-grow-1",
                inputWrapper: "",
                defaultInput: "form-control",
                suggestions: "position-absolute list-group",
                suggestItem: "list-group-item"
            }
        }
    },

    mounted() {
        this.maskedValue = this.local

        this.$watch(
            "local",
            (val, old) => {
                if (this.mask) {
                    this.updateMaskValue(val === undefined || val === null ? "" : val)
                } else if (!this.mask) {
                    this.maskedValue = val
                }
            },
            {
                immediate: true
            }
        )

        this.$refs.base.$el.querySelector("input").setAttribute("autocomplete", this.$attrs.autocomplete || "list")
    },

    methods: {
        assignMask(mask) {
            if (!mask) {
                this.mask = null
                return
            }

            if (typeof mask === "object") {
                this.mask = IMask.createMask(mask)
                return
            }

            let isRegExp = mask.startsWith("/") && mask.endsWith("/")

            this.mask = IMask.createMask({
                mask: isRegExp ? new RegExp(mask.substr(1, mask.length - 2)) : mask
            })
        },

        updateSuggestionsFromUrl: debounce(async function () {
            try {
                let response

                if (typeof this.suggestionsUrl === "function") {
                    const suggestionMethod = await this.suggestionsUrl(this.value)

                    let data = []

                    if (typeof suggestionMethod === "function") {
                        data = await suggestionMethod()
                    } else if (Array.isArray(suggestionMethod)) {
                        data = suggestionMethod
                    } else {
                        data = (await axios.request(suggestionMethod)).data
                    }

                    this.$set(this.item, "data", data)
                } else {
                    response = await this.$api.get(this.suggestionsUrl, { params: { text: this.value } })
                    this.$set(this.item, "data", response.data.data)
                }

                if (Array.isArray(this.item.data) && this.item.data.length > 0) {
                    this.$nextTick(() => {
                        this.$refs.input.research()
                        this.$refs.input.showSuggestions()
                    })
                } else {
                    this.$nextTick(this.$refs.input.hideList)
                }
            } catch (e) {
                console.error("Failed to load suggestions", e.message)
            }
        }, 500),

        onInput(value, programmatically = false) {
            if (!programmatically && this.suggestionsUrl) {
                this.updateSuggestionsFromUrl()
            }

            this.markAsDirty()

            if (this.mask) {
                this.updateMaskValue(value)
            } else {
                this.maskedValue = value
                this.updateLocal(value)
            }
        },

        updateMaskValue(value) {
            this.maskFilled = !this.mask.isComplete

            this.mask.resolve(value.toString())
            this.maskedValue = this.mask.value

            this.updateLocal(this.mask.unmaskedValue)
            this.$nextTick(() => {
                this.$forceUpdate()
            })
        },

        onValueUpdate(val, old) {
            if (this.mask) {
                if (val && this.mask) {
                    this.updateMaskValue(val)
                }
            }
        },

        /**
         * @param suggestion
         */
        onSuggestionSelect(suggestion) {
            let val = this.$refs.input.displayAttribute || "name"

            if (typeof this.item.onSuggestionSelect === "function") {
                this.item.onSuggestionSelect(suggestion[val], suggestion)
            }

            this.onInput(suggestion[val], true)
        },

        focusField() {
            let input = this.$el.querySelector("input")

            if (input) {
                this.$refs.input.isInFocus = true

                input.focus({
                    preventScroll: true
                })
            }
        }
    },

    computed: {
        classes() {
            return [`text-${(this.item && this.item.textAlign) || "left"}`]
        },

        type() {
            return this.attrs.type || "text"
        },

        suggestionTemplate() {
            if (this.item && this.item.suggestionTemplate) {
                if (typeof this.item.suggestionTemplate === "object") {
                    return Object.assign(
                        { props: ["suggestion", "query", "autocomplete"] },
                        this.item.suggestionTemplate
                    )
                }
            }

            return null
        },

        suggestionsUrl() {
            return this.item && this.item.suggestionsUrl
        },

        /**
         * All suggestion options from https://github.com/KazanExpress/vue-simple-suggest
         */
        suggestionOptions() {
            let valueAttribute = "name"
            if (this.item && Array.isArray(this.item.data) && this.item.data.length > 0) {
                valueAttribute = Object.keys(this.item.data[0]).includes("id") ? "id" : "name"
            }
            let baseOptions = {
                "filter-by-query": true,
                "display-attribute": "name",
                "value-attribute": valueAttribute,
                "min-length": this.item && Array.isArray(this.item.data) && this.item.data.length > 0 ? 0 : 1,
                styles: this.autoCompleteStyle
            }

            if (this.item && this.item.suggestionOptions && typeof this.item.suggestionOptions === "object") {
                return Object.assign(baseOptions, this.item.suggestionOptions)
            } else {
                return baseOptions
            }
        },

        suggestions() {
            if (this.item && Array.isArray(this.item.data)) {
                return this.item.data.filter((x) => x.name !== this.value)
            } else {
                return null
            }
        }
    },

    watch: {
        "item.mask": {
            immediate: true,
            handler(val) {
                if (this.item) this.assignMask(this.item.mask)
            }
        }
    }
}
</script>
