<template>
    <div class="topbar position-sticky" :class="{ 'is-scrolled': isScrolled }" style="top: 0">
        <div class="topbar-project primary-text" style="font-weight: bold; padding: 8px">
            {{ menuCollapsed ? $const.APP_SHORT_NAME[0].toUpperCase() : $const.APP_SHORT_NAME }}
        </div>
        <Snowf
            v-if="!isWinterDisabled"
            :amount="7"
            :size="5"
            :speed="0.3"
            :wind="0"
            :opacity="0.8"
            :swing="1"
            :image="null"
            :zIndex="-1"
            :resize="true"
            color="#fff"
        />
    </div>
</template>

<script>
import Snowf from "./Winter.vue"
import { mapGetters } from "vuex"
import WinterMixin from "@Platon/mixins/WinterMixin"

export default {
    name: "LeftMenuProject",
    components: { Snowf },
    mixins: [WinterMixin],
    props: { isScrolled: { type: Boolean, default: false } },
    computed: {
        ...mapGetters({
            menuCollapsed: "platon/menuCollapsed"
        })
    }
}
</script>
<style>
.topbar.is-scrolled {
    box-shadow: 0px 23px 45px -17px rgba(34, 60, 80, 0.2);
}
</style>
