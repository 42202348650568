<template>
    <div class="left-menu nv-left-menu" :class="{ collapsed: menuCollapsed }" v-on:scroll="handleScroll">
        <component :is="project" :is-scrolled="isScrolled"></component>
        <component :is="profile"></component>
        <component :is="menu"></component>
        <component :is="footer"></component>
    </div>
</template>

<script>
import PlatonMenu from "@Platon/components/menu/PlatonMenu.vue"
import LeftMenuProfile from "@Platon/components/layout/LeftMenuProfile.vue"
import LeftMenuProject from "@Platon/components/layout/LeftMenuProject.vue"
import LeftMenuFooter from "@Platon/components/layout/LeftMenuFooter.vue"
import { mapGetters } from "vuex"

export default {
    name: "PlatonLeftMenu",
    components: { LeftMenuProfile, PlatonMenu },
    data() {
        return {
            isScrolled: false
        }
    },

    computed: {
        ...mapGetters({
            menuCollapsed: "platon/menuCollapsed"
        }),

        profile() {
            return (
                this.$root.$options.components["sidebarProfile"] ||
                this.$root.$options.components["custom-sidebar-profile"] ||
                LeftMenuProfile
            )
        },
        project() {
            return (
                this.$root.$options.components["sidebarProject"] ||
                this.$root.$options.components["custom-sidebar-project"] ||
                LeftMenuProject
            )
        },

        menu() {
            return this.$root.$options.components["custom-sidebar-menu"] || PlatonMenu
        },

        footer() {
            return (
                this.$root.$options.components["sidebarFooter"] ||
                this.$root.$options.components["custom-sidebar-footer"] ||
                LeftMenuFooter
            )
        }
    },
    methods: {
        handleScroll(e) {
            if (e.srcElement.scrollTop >= 100) {
                this.isScrolled = true
            } else {
                this.isScrolled = false
            }
        }
    }
}
</script>

<style>
/* .brand {
    margin: 0 1rem;
    font-size: 18px;
    font-weight: bold;
} */
</style>
