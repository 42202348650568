/**
 * @param {Vue} app
 * @param {UserOrganization[]} organizations
 * @param options
 */
export async function pickUserOrganization(
	app,
	organizations,
	options = { closeable: false, orgId, searchBy: "name" }
) {
	let component = () => import("../components/login/PickUserOrganization.vue")

	return new Promise((resolve, reject) => {
		app.$modal.show(
			component,
			{
				organizations,
				orgId: options.orgId,
				searchBy: options.searchBy,
				canCancel: options.closeable,
				onSelected: (/* string */ orgId) => {
					resolve(orgId)
				}
			},
			{ clickToClose: options.closeable, height: "auto" }
		)
	})
}
