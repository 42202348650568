import { setRootToast } from "@Platon/core/core"
import { API_BASE_URL, API_BASE_AUTH_URL, Keys, LANG_DEFINITION, AUTH_LOGIN_URL } from "@Platon/const"
import PlatonApp from "@Platon/components/PlatonApp.vue"
import ModalMixin from "@Platon/mixins/ModalMixin"
import { $language, localeInfo, switchLocale } from "@Platon/core/translations"
import ToastMixin from "@Platon/mixins/ToastMixin"
import ContextMenuMixin from "@Platon/components/extended/ContextMenuMixin"
import { mapGetters, mapMutations } from "vuex"
import PlatonThemeMixin from "@Platon/mixins/PlatonThemeMixin"
import { themeChanger } from "@Platon/core/theme/theme-change"

/**
 * @param Vue
 * @param {PlatonCoreOptions} options
 * @return {Vue}
 */
export default function (Vue, options) {
	let router = options.router

	let platonApp = document.createElement("div")
	document.body.append(platonApp)

	const $platonInstance = new Vue({
		mixins: [ModalMixin, ToastMixin, ContextMenuMixin, PlatonThemeMixin],
		store: options.store,
		render: (h) => h(PlatonApp),
		name: "PlatonAppInstance",

		created: function () {
			setRootToast(this.$bvToast)
		},

		computed: {
			...mapGetters({
				isAuth: "platon/isAuth"
			}),

			langs() {
				return LANG_DEFINITION
			},

			currentLangInfo() {
				return this.langs.find((x) => x.code === this.currentLang)
			},

			currentLang() {
				return localeInfo.locale
			}
		},

		methods: {
			...mapMutations({
				changeLocale: "platon/changeLocale"
			}),

			async setLocale(locale) {
				if (this.isAuth) {
					this.$http
						.get(`/user-locale/?locale=${locale}`, {
							baseURL: API_BASE_URL
						})
						.then(() => {
							this.changeLocale(locale)

							switchLocale(locale)
						})
				} else {
					await switchLocale(locale)
				}
			},

			toggleDevMode() {
				this.$store.commit("platon/setForceUserMode", !this.$store.state.platon.forceUserMode)
			},

			/**
			 * @typedef EIMZOSignOptions
			 * @property {?string} certSerialNumber
			 * @property {?Object} loadedKey
			 * @property {?function} closed
			 * @property {?function} timestampProvider
			 * @property {?boolean} autoSign
			 * @property {?string[]} whiteList TIN's or PINFL's of cert's can be indicated here to filter certs
			 */

			/**
			 * @param {string|string[]|function|function[]} text
			 * @param {?function} onSign
			 * @param {EIMZOSignOptions} options
			 */
			signWithEIMZO(text, onSign, options = {}) {
				let modalEvents = {}

				if (options.closed) modalEvents.closed = options.closed
				if (!options.timestampProvider) options.timestampProvider = this.getTimestampForSignature

				this.$modal.show(
					() => import("@Platon/components/eimzo/EIMZOSign.vue"),
					{
						signText: text,
						onSign,
						timestampProvider: options.timestampProvider,
						options
					},
					{
						height: "auto",
						width: Math.min(window.innerWidth - 32, 500)
					},
					modalEvents
				)
			},

			/**
			 * @param {string|string[]|function|function[]} text
			 * @param {?Function} onSign
			 * @param {EIMZOSignOptions} options
			 */
			signAsLoggedUser(text, onSign, options = {}) {
				let keyInfo
				try {
					keyInfo = JSON.parse(localStorage.getItem("login_eimzo_key") || "{}")

					keyInfo = {
						loadedKey: keyInfo,
						certSerialNumber: keyInfo.cert.serialNumber
					}
				} catch (e) {
					keyInfo = {}
				}

				if (!keyInfo.certSerialNumber) {
					/**
					 * @type {User}
					 */
					let user = this.$store.state.platon.user

					if (user) keyInfo.certSerialNumber = user.orgId || user.tin
				}

				function captureOnSign(signResult, loadedKey) {
					localStorage.setItem("login_eimzo_key", JSON.stringify(loadedKey))

					onSign && onSign(...arguments)
				}

				this.signWithEIMZO(text, captureOnSign, {
					...options,
					...keyInfo
				})
			},

			/**
			 * @param {string} signatureHex
			 */
			async getTimestampForSignature(signatureHex, cb) {
				try {
					const timestampResult = await this.$api.post(
						"eimzo/timestamp",
						{ pkcs7: signatureHex },
						{ baseURL: API_BASE_AUTH_URL }
					)

					cb && cb(timestampResult.data)

					return timestampResult.data.pkcs7b64
				} catch (e) {
					this.errorToast($language("platon.timestamp_error", "Timestamp олишда хатолик"))
					return
				}
			},

			showLogoutDialog(afterLogout) {
				this.confirmAction(
					async () => {
						// let localUrl = localStorage["login_url"] || this.$router.resolve({ name: "login" }).href
						let url =
							process.env.NODE_ENV === "development"
								? AUTH_LOGIN_URL
								: `${window.location.origin}/auth/login`

						await this.setThemeToDefault()

						await this.$store.dispatch("platon/logout", { redirectUrl: url, afterLogout })
					},
					{
						text: $language("platon.confirm_logout", "Тизимдан чиқишни хоҳлайсизми?")
					}
				)
			},

			async setThemeToDefault() {
				await this.$store.commit("platon/setTheme", {
					theme: "platon",
					color: "platon"
				})

				const change = themeChanger()
				change(this.$store.state.platon.theme)
			}
		}
	}).$mount(platonApp)

	window.addEventListener("keyup", (ev) => {
		if (ev.shiftKey && ev.ctrlKey && ev.keyCode === Keys.D) {
			$platonInstance.toggleDevMode()
		}
	})

	window.q = function (queryName) {
		return router.currentRoute.query[queryName]
	}

	return $platonInstance
}
