let env = process.env

let {
	PLATON_SERVER_URL,
	PLATON_APP_NAME,
	PLATON_SERVER_PREFIX,
	PLATON_SHORT_NAME,
	PLATON_API_URL,
	PLATON_LOGIN_URL,
	PLATON_DOMAIN,
	PLATON_APP_LANGS,
	PLATON_SERVER_URL_AUTH,
	PLATON_AUTH_PORT,
	PLATON_LOGIN_PAGE,
	PLATON_LOGIN_AUTH_PAGE,
	PLATON_API_SUFFIX,
	PLATON_ROUTER_BASE_URL,
	PLATON_LOGIN_PROVIDER,
	PLATON_DEFAULT_LANG,
	PLATON_COPYRIGHT_URL,
	PLATON_AUDIT_LINK,
	PLATON_EIMZO_CLIENT,
	PLATON_EIMZO_KEY,
	PLATON_HOME_REDIRECT,
	PLATON_SENTRY_DNS,
	PLATON_ENABLE_SENTRY,
	PLATON_SENTRY_URL
} = window.configs

export {
	PLATON_LOGIN_PAGE,
	PLATON_LOGIN_AUTH_PAGE,
	PLATON_EIMZO_CLIENT,
	PLATON_EIMZO_KEY,
	PLATON_HOME_REDIRECT,
	PLATON_SENTRY_DNS,
	PLATON_ENABLE_SENTRY,
	PLATON_SENTRY_URL,
	PLATON_ROUTER_BASE_URL
}

export const DEV_MODE = localStorage.getItem("DEV_MODE") === "1"

export function DevLog(key, ...args) {
	if (DEV_MODE) console.log(new Date().toISOString(), key, ...args)
}

export const API_SUFFIX = PLATON_API_SUFFIX || env.VUE_APP_API_SUFFIX
export const LOCAL_AUTH_URL = "http://localhost:" + PLATON_AUTH_PORT
export const AUTH_LOGIN_URL = "http://localhost:" + PLATON_AUTH_PORT + PLATON_LOGIN_AUTH_PAGE

export const DEFAULT_THEME = env.VUE_APP_DEFAULT_THEME || "platon"
export const DEFAULT_THEME_COLOR = env.VUE_APP_DEFAULT_THEME_COLOR || "platon"

export const IS_PROD = process.env.NODE_ENV === "production"
export const IS_DEV = env.NODE_ENV === "development" || env.DEBUG === true

export const APP_DOMAIN = IS_DEV ? "localhost" : PLATON_DOMAIN

export const API_BASE_URL = PLATON_SERVER_URL + "/" + PLATON_SERVER_PREFIX
export const SERVER_ENDPOINT = API_BASE_URL + PLATON_API_URL
export const FRONTEND_URL = env.VUE_APP_FRONTEND_URL || location.origin
export const API_ENDPOINT = API_BASE_URL + (API_SUFFIX === undefined ? "api" : API_SUFFIX)

export const API_BASE_AUTH_URL = PLATON_SERVER_URL_AUTH

export const LOGIN_ENDPOINT = PLATON_LOGIN_URL
export const UPLOAD_ENDPOINT = "files/upload/form"
export const PRIVATE_UPLOAD_ENDPOINT = "web/v1/public/forms/file/upload"
export const DOWNLOAD_ENDPOINT = API_BASE_URL + "uploads/files"
export const IMAGE_ENDPOINT = API_BASE_URL + "uploads/images"

export const FRONTEND_PREFIX = PLATON_ROUTER_BASE_URL || env.VUE_APP_ROUTER_BASE_URL

// used in login page
export const APP_NAME = PLATON_APP_NAME || "Platon"
// used in app toolbar
export const APP_SHORT_NAME = PLATON_SHORT_NAME || APP_NAME

export const BACKEND_TIMEZONE = env.VUE_APP_SERVER_TIMEZONE || "Asia/Tashkent"
export const DEFAULT_DATE_FORMAT = "DD.MM.YYYY"
export const DEFAULT_DATETIME_FORMAT = "DD.MM.YYYY HH:mm"
export const DEFAULT_TIME_FORMAT = "HH:mm"
export const DEFAULT_SERVER_DATE_FORMAT = "YYYY-MM-DD"
export const DEFAULT_SERVER_DATETIME_FORMAT = "YYYY-MM-DDTHH:mm:ssZ"

export const ENABLED_LANGS = (PLATON_APP_LANGS || "uz,la,ru,en,qq").split(",")

// ! TO DO (componentga chiqariladi, vaxtinchalik)
export const CONTACT_PHONE = env.VUE_APP_CONTACT_PHONE || "+998 (97) 136-84-84"
export const CONTACT_TELEGRAM = env.VUE_APP_CONTACT_TELEGRAM || "https://t.me/realsoft_uz"

export const LANG_DEFINITION = [
	{ code: "uz", name: "Ўзбекча" },
	{ code: "la", name: "Oʻzbekcha" },
	{ code: "ru", name: "Русский" },
	{ code: "en", name: "English" },
	{ code: "qq", name: "Qaraqalpaqsha" },
	{ code: "kh", name: "Khmer" }
].filter((lang) => ENABLED_LANGS.includes(lang.code))

export const AVAILABLE_LOGIN_PROVIDERS = [
	"default",
	"eimzo",
	"google",
	"facebook",
	"one_id",
	"keycloak",
	"linkedin",
	"ssv"
]

export const ENABLED_LOGIN_PROVIDERS = (PLATON_LOGIN_PROVIDER || "default")
	.split(",")
	.filter((provider) => AVAILABLE_LOGIN_PROVIDERS.includes(provider))

export const APP_VERSION = require("../package.json").version || "0.0.0"
export const DEFAULT_LANG = PLATON_DEFAULT_LANG || "uz"
export const COPYRIGHT_URL = PLATON_COPYRIGHT_URL || env.VUE_APP_COPYRIGHT_URL || ""
export const COPYRIGHT = (env.VUE_APP_COPYRIGHT || `RealSoft 2020-${new Date().getFullYear()}`).replace(
	"{Y}",
	new Date().getFullYear().toString()
)

export const IN_SITE_COPYRIGHT = env.VUE_APP_IN_SITE_COPYRIGHT || COPYRIGHT

/**
 * @param {string} resourceName
 * @return {string}
 * @constructor
 */

// !TODO HOTFIX

function getCookie(name) {
	let cookieArray = document.cookie.split(";") // Split the cookie string into an array
	for (let i = 0; i < cookieArray.length; i++) {
		let cookiePair = cookieArray[i].split("=") // Split each key-value pair
		if (name == cookiePair[0].trim()) {
			// Check if the name matches
			return decodeURIComponent(cookiePair[1]) // Decode and return the cookie value
		}
	}
	return null // Return null if the cookie is not found
}

export const EXPORT_RESOURCE_URL = (resourceName) => {
	// trim slash
	if (typeof resourceName === "string" && resourceName.startsWith("/")) {
		resourceName = resourceName.substr(1)
	}

	let base = `${API_BASE_URL}export/${resourceName}`
	let token = `_bearer_token=${getCookie("auth_token")}`
	// let token = `_bearer_token=${cookie.get("auth_token")}`

	return resourceName.includes("?") ? `${base}&${token}` : `${base}?${token}`
}

export const Keys = {
	ArrowUp: 38,
	ArrowDown: 40,
	Enter: 13,
	Esc: 27,
	D: 68,
	I: 73
}

export const DEFAULT_MAP_CENTER = [41.311081, 69.240562]

export const CONSTANTS = {
	IS_DEV,
	API_BASE_URL,
	SERVER_ENDPOINT,
	API_ENDPOINT,
	LOGIN_ENDPOINT,
	UPLOAD_ENDPOINT,
	FRONTEND_URL,
	DOWNLOAD_ENDPOINT,
	FRONTEND_PREFIX,
	APP_NAME,
	APP_SHORT_NAME,

	BACKEND_TIMEZONE,
	DEFAULT_DATE_FORMAT,
	DEFAULT_DATETIME_FORMAT,
	DEFAULT_TIME_FORMAT,
	DEFAULT_SERVER_DATE_FORMAT,
	DEFAULT_SERVER_DATETIME_FORMAT,

	AVAILABLE_LOGIN_PROVIDERS,
	ENABLED_LOGIN_PROVIDERS,

	LANG_DEFINITION,
	APP_VERSION,
	DEFAULT_LANG,
	COPYRIGHT,
	IN_SITE_COPYRIGHT,
	COPYRIGHT_URL,
	EXPORT_RESOURCE_URL,
	Keys,
	DEFAULT_MAP_CENTER,
	// TO DO (componentga chiqariladi, vaxtinchalik)
	CONTACT_PHONE,
	CONTACT_TELEGRAM
}

export const AUDIT_URL = PLATON_AUDIT_LINK || env.VUE_APP_AUDIT_LINK
export const AUDIT_TYPES = {
	loggedIn: "Foydalanuvchi profilga kirdi",
	loggedOut: "Foydalanuvchi profildan chiqdi",

	tableRead: "Jadval ochildi",
	pageRead: "Sahifa ochildi",
	formRead: "Forma ochildi",
	formInsert: "Formadan malumot yaratildi",
	formUpdate: "Formadan malumot yangilandi",
	formDelete: "Formadan malumot ochirildi",
	formHardDelete: "Formadan malumot toliq ochirildi"
}

export const PlatonFormNames = [
	"menus",
	"api_endpoints",
	"api_stages",
	"cron_crud",
	"document_templates",
	"excel_import_configs",
	"excel_import_row_handlers",
	"export_resources",
	"file_categories",
	"form_elements",
	"forms",
	"instructions",
	"platon_page_components",
	"platon_pages",
	"table_buttons",
	"table_columns",
	"table_filters",
	"tables",
	"translations",
	"user_logs"
]

export const PlatonTableNames = [
	"menus",
	"forms",
	"form_elements",
	"tables",
	"table_columns",
	"table_filters",
	"table_buttons",
	"file_categories",
	"cron",
	"api_endpoints",
	"excel_import_configs",
	"user_logs",
	"api_metrics",
	"export_resources",
	"translations",
	"sql_metrics",
	"document_templates",
	"platon_pages",
	"platon_page_components",
	"instructions"
]

export const themeModes = [
	{
		key: "platon",
		name: "Platon",
		colors: [
			{
				key: "platon",
				name: "Platon",
				pickerHex1: "#FFFFFF",
				pickerHex2: "#69BB8E",
				pickerHex3: "#3D5C95"
			},
			{
				key: "dark",
				name: "Dark",
				pickerHex1: "#FFFFFF",
				pickerHex2: "#5163ac",
				pickerHex3: "#3391FA"
			},
			{
				key: "deepDark",
				name: "Deep dark",
				pickerHex1: "#464646",
				pickerHex2: "#3D6EF5",
				pickerHex3: "#171717"
			},
			{
				key: "light",
				name: "Light",
				pickerHex1: "#FFF",
				pickerHex2: "#e3e7f5",
				pickerHex3: "#3657d9"
			},
			{
				key: "green",
				name: "Green",
				pickerHex1: "#59C398",
				pickerHex2: "#3D56D1",
				pickerHex3: "#508553"
			},
			{
				key: "darkBlue",
				name: "Dark-blue",
				pickerHex1: "#59C398",
				pickerHex2: "#3D56D1",
				pickerHex3: "#071142"
			}
		]
	},
	{
		key: "oceanic",
		name: "Oceanic",
		colors: [
			{
				key: "oceanicBlue",
				name: "Blue",
				pickerHex1: "#2687EF",
				pickerHex2: "#F3F9FF",
				pickerHex3: "#0058B0"
			},
			{
				key: "oceanicGreen",
				name: "Green",
				pickerHex1: "#00BE76",
				pickerHex2: "#E6B34F",
				pickerHex3: "#3E7E76"
			},
			{
				key: "oceanicLight",
				name: "Light",
				pickerHex1: "#FFFFFF",
				pickerHex2: "#3D56D1",
				pickerHex3: "#E4E7F4"
			}
		]
	},
	{
		key: "precious",
		name: "Precious",
		colors: [
			{
				key: "preciousSilver",
				name: "Silver",
				pickerHex1: "#5B5B5B",
				pickerHex2: "#C6C6C6",
				pickerHex3: "#D9D9D9"
			},
			{
				key: "preciousGold",
				name: "Gold",
				pickerHex1: "#BC8A26",
				pickerHex2: "#DDB74E",
				pickerHex3: "#FBEC9B"
			},
			{
				key: "preciousPlatinum",
				name: "Platinum",
				pickerHex1: "#8D92AD",
				pickerHex2: "#A4A9B8",
				pickerHex3: "#D9E0E8"
			}
		]
	}
]

export const hexColors = {
	platon: "#3D5C95",
	dark: "#3391FA",
	deepDark: "#464646",
	light: "#4D565F",
	green: "#508553",
	darkBlue: "#3D56D1",
	oceanicBlue: "#0158B0",
	oceanicGreen: "#3E7E76",
	preciousSilver: "#5B5B5B",
	preciousGold: "#BC8A26",
	preciousPlatinum: "#8D92AD",
	oceanicLight: "#E4E7F4"
}

// Oy nomi inglizchada kiritiladi.
export const LAST_WINTER_DATE = env.VUE_APP_LAST_WINTER_DATE || "February 15"
