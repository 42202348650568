<template>
    <div
        @drop.prevent="onFileDrop"
        @dragover.prevent="dragging = true"
        @dragleave.prevent="dragging = false"
        @click="$refs.input.click()"
        :class="{ dragging }"
        class="upload-area p-3 d-flex align-items-center justify-content-center flex-column h4 cursor-pointer"
    >
        <div><i style="font-size: 2rem" class="fa fa-upload" /></div>
        <div>Файл юклаш</div>

        <input @change="onFileSelected" type="file" hidden ref="input" />

        <div v-if="file" class="text-center mt-3 h6">{{ file.name }}, {{ file.size | prettyBytes }}</div>

        <div v-if="uploading" class="text-center">{{ uploadPercent }}%</div>
    </div>
</template>

<script>
export default {
    name: "UploadArea",

    props: {
        value: {},

        uploadEndpoint: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            /** @type {File} */
            file: null,

            uploading: false,
            uploadPercent: 0,
            dragging: false
        }
    },

    mounted() {},

    methods: {
        /**
         * @param {DragEvent} event
         */
        onFileDrop(event) {
            this.dragging = false
            let files = event.dataTransfer.files

            if (!files) return

            this.file = files.item(0)

            this.$refs.input.files = [this.file]
        },

        onFileSelected(event) {
            this.file = this.$refs.input.files[0]
        },

        async upload() {
            this.uploading = true

            let formData = new FormData()
            formData.append("file", this.file)

            await this.$http.post(this.uploadEndpoint, formData, {
                baseURL: "/",
                onUploadProgress: (/** ProgressEvent */ progressEvent) => {
                    this.uploadPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                }
            })

            this.uploading = false
        }
    },

    watch: {
        file: {
            handler(file) {
                this.$emit("input", file)
            }
        }
    }
}
</script>

<style scoped lang="scss">
.dragging {
    border-color: #09f;
}
</style>
